
import PreviewRiskPopup from './toolBox/previewRiskPopup.vue';
export default {
    name: 'caseRisk',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        },
        suspensionBtnObj: {
            typeof: Object,
            default: () => {}
        },
        tmpOSSClient: {
            type: Object,
            default: null
        }
    },
    components: {
        PreviewRiskPopup
    },
    data() {
        return {
            tokenPreviewShow: false,
            previewShow: false,
            isFold: true,
            OSSClient: null,
            riskTitle: '',
            innerVisible: false,
            isEditInfo: false,
            showRepeatReport: false,
            materialTypeForm: {
                code: ''
            },
            fileTypeList: [],
            caseMaterialsType: [],
            checkedMaterialType: {},
            activeNameArray: [],
            riskNumData: {
                num: 0
            },
            previewRiskData: []
        };
    },
    methods: {
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        },

        // 关闭重复案件提示框
        closeTipsDialog() {
            this.showRepeatReport = false;
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code === 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        //点击修改材料类型按钮
        handleEditType(item, par) {
            this[par] = true;
            this.checkedMaterialType = item ? item : null;
            this.titleMaterialType = item ? '修改材料类型' : '选择材料类型';
            this.materialTypeForm = item ? this.materialTypeForm : { code: '' };
        },
        initImgUrlAll() {
            // console.log('案件风险图片初始化');
            this.previewRiskData = null;
            this.tokenPreviewShow = false;

            if (this.caseDetail && this.caseDetail.caseRiskVos) {
                let tmpCaseRiskVos = this.caseDetail.caseRiskVos;

                if (this.OSSClient) {
                    tmpCaseRiskVos.forEach((res) => {
                        // 通过oss换取imgurl
                        res.highRiskInfos && this.tradeImgUrl(res.highRiskInfos);
                        res.mediumRiskInfos && this.tradeImgUrl(res.mediumRiskInfos);
                        res.lowRiskInfos && this.tradeImgUrl(res.lowRiskInfos);
                        this.$forceUpdate();
                    });
                    this.previewRiskData = tmpCaseRiskVos;
                    this.tokenPreviewShow = true;
                    if (this.tmpPreviewData) {
                        this.$refs.previewRiskPopup && this.$refs.previewRiskPopup.switchRiskImg(this.tmpPreviewData.riskCardList, this.tmpPreviewData.currentRiskItem, this.tmpPreviewData.currentRiskIndex, this.tmpPreviewData.riskCardIndex);
                    }
                    this.$forceUpdate();
                    this.tmpPreviewData = null;
                } else {
                    setTimeout(() => {
                        this.initImgUrlAll();
                    }, 500);
                }
            }
        },
        tradeImgUrl(tmpImgList) {
            tmpImgList.forEach((item) => {
                if (item.imageList) {
                    item.imageList.forEach((imgItem) => {
                        let imgType = ['.bmp', '.dib', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];

                        imgItem.url = imgItem.materialPath ? this.OSSClient.signatureUrl(imgItem.materialPath) : imgItem.materialOriginalPath;

                        // 判断图片类型
                        let tmpUrlArr = imgItem.url.split('?');
                        tmpUrlArr = tmpUrlArr[0];
                        let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                        let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                        if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                            let tmpSuffix = suffix.split('%')[0];
                            suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                        }

                        // 图片后缀

                        imgItem.isImg = Boolean(suffix != -1);
                        imgItem.suffix = suffix;
                        this.$forceUpdate();
                    });
                }
            });
        },
        showRiskPreviewDialog(riskCardIndex, riskCardList, currentRiskItem, currentRiskIndex) {
            this.previewShow = true;
            this.tmpPreviewData = {
                riskCardIndex: riskCardIndex,
                riskCardList: riskCardList,
                currentRiskItem: currentRiskItem,
                currentRiskIndex: currentRiskIndex
            };
            this.$nextTick(() => {
                this.$refs.previewRiskPopup && this.$refs.previewRiskPopup.switchRiskImg(riskCardList, currentRiskItem, currentRiskIndex, riskCardIndex);
            });
        },
        closeRiskDialog(flag) {
            this.previewShow = flag;
        }
    },
    watch: {
        tmpOSSClient: {
            handler(val) {
                if (val) {
                    // console.log('caseRisk');
                    // console.log(val);

                    this.OSSClient = val;
                    this.initImgUrlAll();
                }
            },
            immediate: true
        },
        suspensionBtnObj(val) {
            if (val && val.tmpHandle) {
                this.isEditInfo = val.tmpHandle.subscriptShow ? false : true;
            } else if (localStorage.getItem('detailShow')) {
                this.isEditInfo = localStorage.getItem('detailShow') == 'show' ? false : true;
            } else {
                this.isEditInfo = val && val.tmpStep && val.tmpStep.code && val.tmpStep.code != 41 && val.tmpStep.code != 59 && val.tmpStep.code != 75 && val.tmpStep.code != 70 && val.tmpStep.code != 50;
            }
        },
        caseDetail: {
            handler(val) {
                if (val && val.caseRiskVos) {
                    this.riskNumData.num = 0;
                    val.caseRiskVos.forEach((item, index) => {
                        item.highRiskInfos &&
                            item.highRiskInfos.forEach((risk, riskIndex) => {
                                if (risk.riskCode === 'A6') {
                                    this.showRepeatReport = true;
                                }
                            });
                        this.activeNameArray.push(index);

                        this.riskNumData.num = this.riskNumData.num + item.highCount + item.mediumCount + item.lowCount;
                        this.riskNumData.color = item.highCount > 0 ? 'errorColorBgc' : item.mediumCount > 0 ? 'warningColorBgc' : 'successColorBgc';
                    });

                    // 风险预览弹窗数据

                    // this.previewRiskData = val.caseRiskVos;
                    this.initImgUrlAll();
                }
            },
            immediate: true
        }
    }
};
